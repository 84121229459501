@import '../../../../../assets/scss/queries-mixins.scss';

.apscores-sendscores-diselections {
  &:not(:empty) {
    margin-bottom: 48px;

    @include media('sm', 'md') {
      margin-bottom: 40px;
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }

  h3 {
    margin-bottom: 12px;
  }

  .cb-glyph-numeric {
    font-size: 12px;
    height: 19px;
    width: auto;
    min-width: 19px;
    padding: 0 2px;
    line-height: 19px !important;
  }

  .apscores-sendscores-diselection {
    padding: 24px 26px;

    @include mobile {
      padding: 16px 14px;
    }

    p {
      @include mobile {
        font-size: 14px;
      }

      &:first-child {
        font-size: 19px;

        @include mobile {
          font-size: 16px;
        }
      }
    }
  }
}

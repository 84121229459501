@import '../../../../../assets/scss/queries-mixins';
@import '../../../../../assets/scss/variables';

.which-scores {
  margin-bottom: 48px;

  @include media('sm', 'md') {
    margin-bottom: 40px;
  }

  @include mobile {
    margin-bottom: 32px;
  }

  h3 {
    @include mobile {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

.which-scores-img {
  @include mobile {
    margin: 24px 0;
  }
  margin: 0 55px;
  height: 179px;
  width: 176px;
  background: no-repeat center center url('../../../../../assets/img/'+$which-scores-img);
}

.cb-dropdown-toggle {
  .cb-dropdown-glyph-user {
    font-family: CB Icons;
    font-style: normal;
    font-weight: normal;
    margin-right: 8px;
    &:before {
      content: '\e952';
    }
  }
}

.pdf-process-container {
  visibility: hidden;
  position: absolute;
}

.pdf-container {
  padding: 0 40px;

  .pdf-footer {
    page-break-after: always !important;
  }

  .pdf-header {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 14px !important;
    padding-bottom: 10px;
    margin-top: 40px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 40px;
  }

  .pdf-content {
    height: 890px;
  }

  .cb-notification-content {
    padding-left: 0 !important;
    font-size: 14px !important;
  }
  .cb-notification-container {
    padding: 12px !important;
    margin-top: 0 !important;
  }

  // make font sizes static
  font-size: 16px !important;
  line-height: 24px !important;

  // make items flush
  .cb-padding-left-24 {
    padding-left: 0 !important;
  }

  h2 {
    font-size: 28px !important;
    line-height: 32px !important;
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    font-weight: 400px;
  }

  h3 {
    font-size: 21px !important;
    line-height: 24px !important;
    margin-top: 32px !important;
  }

  h4 {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  h5 {
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    page-break-inside: avoid;
  }

  .cb-paragraph1-res {
    font-size: 16px !important;
    line-height: 21px !important;
    page-break-inside: avoid;
  }

  .cb-font-size-small {
    font-size: 14px !important;
  }

  .cb-font-size-xsmall {
    font-size: 12px !important;
  }

  .cb-font-size-regular {
    font-size: 16px !important;
  }

  p {
    padding-bottom: 0 !important;

    + p {
      margin-top: 16px !important;
    }
  }

  // decrease overall margin/padding
  .cb-margin-bottom-16 {
    margin-bottom: 8px !important;
  }
  .cb-margin-top-16 {
    margin-top: 8px !important;
  }
  .cb-padding-bottom-16 {
    padding-bottom: 8px !important;
  }
  .cb-padding-top-16 {
    padding-top: 8px !important;
  }
  .cb-margin-bottom-24 {
    margin-bottom: 12px !important;
  }
  .cb-margin-top-24 {
    margin-top: 12px !important;
  }
  .cb-padding-bottom-24 {
    padding-bottom: 12px !important;
  }
  .cb-padding-top-24 {
    padding-top: 12px !important;
  }
  .cb-margin-top-48 {
    margin-top: 24px !important;
  }
  .cb-margin-bottom-72 {
    margin-bottom: 16px !important;
  }
  .cb-margin-top-72 {
    margin-top: 48px !important;
  }
}

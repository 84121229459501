@import '../../../../assets/scss/queries-mixins.scss';
@import '../../../../assets/scss/variables.scss';

$img-hero: '../../../../assets/img/img-hero.webp';

.apscores-image-hero {
  color: #ffffff;
  min-height: 178px;
  position: relative;
  background: url($img-hero) no-repeat center;
  background-size: cover;

  @include small {
    min-height: 162px;
  }

  @include mobile {
    min-height: 130px;
    background-position: top 0 left 40%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(
      to left,
      rgba($overlay-blue, 0.45) 0%,
      rgba($overlay-blue, 0.55) 25%,
      rgba($overlay-blue, 0.65) 70%,
      $overlay-blue 100%,
      rgba($overlay-blue, 0.95) 100%
    );
  }

  .container,
  .row {
    min-height: inherit;
  }
}

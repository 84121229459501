@import '../../../../assets/scss/queries-mixins.scss';

body.apscores-autosuggest-open {
  @include mobile {
    overflow: hidden;
  }
}

// these styles are overrides to apricot to combine "input with dropdown" with "input with floating label"
// custom changes:
// - "clear" on mobile-only
// - full-screen dropdown experience on mobile-only
// - container style that wraps input and dropdown on focus of either
// - copy styles from apricot for inputs on non-white backgrounds and only show when autosuggest is not "open"
.apscores-autosuggest {
  .cb-input-dropdown {
    button {
      display: none !important;
    }
  }

  &:not(.apscores-autosuggest-open) {
    .cb-value-fl,
    .cb-focus-fl {
      color: #1e1e1e;
      top: -20px;
      background: transparent;
    }
  }

  @include mobile {
    &.apscores-autosuggest-active-close {
      .cb-value-fl,
      .cb-focus-fl {
        color: #1e1e1e;
        top: -20px;
        background: transparent;
      }
    }
  }

  &.apscores-autosuggest-open {
    &:not(.apscores-autosuggest-active-close) {
      .cb-input-dropdown.cb-input {
        @include mobile {
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          position: fixed;
          z-index: 1000;
          border-radius: 0;
          overflow-y: scroll;
          background: #ffffff;
          padding: 24px 24px 0 24px;
        }
      }

      button {
        @include mobile {
          display: block !important;
        }
      }
    }

    .cb-input-dropdown.cb-input {
      @include not-mobile {
        padding: 16px 24px 0 24px;
        left: -24px;
        top: -16px;
        background: #ffffff;
        width: calc(100% + 48px);
        z-index: 200;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
      }

      input {
        border-bottom: 1px solid #d9d9d9;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-color: #d9d9d9;

        &:not(:disabled):focus {
          border: 1px solid #324dc7;
        }
      }

      > div {
        position: relative;
      }
    }

    .cb-dropdown-input {
      margin-top: 8px;
      margin-left: -24px;
      width: calc(100% + 48px);
    }

    .cb-dropdown-menu {
      ul li a {
        white-space: pre-wrap;
      }
    }
  }
}

@import '../../../assets/scss/queries-mixins.scss';

.apscores-content-module {
  min-height: 250px;

  h4 {
    font-size: 32px;
    line-height: 36px;
    font-weight: 400;

    @include mobile {
      font-size: 24px;
    }
  }

  p {
    font-weight: 300;
    font-size: 21px;

    &.cb-roboto,
    strong {
      font-weight: 400;
    }

    @include mobile {
      font-size: 16px;
      line-height: 24px;
    }

    @include media('md', 'lg', '2xl') {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

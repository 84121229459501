@import '../../../../assets/scss/queries-mixins.scss';
@import '../../../../assets/scss/variables.scss';

$img-award-release-date: '../../../../assets/img/img-award-release.webp';

.apscores-award-release {
  position: relative;
  color: #ffffff;
  background-image: url($img-award-release-date);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right 20%;

  @include mobile {
    background-position: top 0 right 28%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      $overlay-blue,
      rgba($overlay-blue, 0.9) 52%,
      rgba($overlay-blue, 0.5)
    );
  }

  > * {
    z-index: 1;
    position: relative;
  }
}

@import '../../../assets/scss/queries-mixins.scss';

.apscores-band {
  margin-bottom: 80px;

  @include media('sm', 'md') {
    margin-bottom: 72px;
  }

  @include mobile {
    margin-bottom: 40px;
  }

  .cb-band-hero-content {
    padding: 48px 0 !important;

    @include mobile {
      padding: 24px 0 !important;
    }

    .cb-stepper {
      @include mobile {
        padding-top: 0;
      }
    }

    .apscores-band-title {
      font-size: 48px;
      line-height: 1.2em;
      padding-bottom: 12px;

      @include media('md', 'sm') {
        font-size: 40px;
      }

      @include mobile {
        font-size: 24px;
        line-height: 1em;
      }
    }

    .apscores-band-subtitle {
      padding-bottom: 8px;
      @include mobile {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
}

@import '../../../../../assets/scss/queries-mixins.scss';

.apscores-intro-modal {
  .cb-modal-container {
    position: relative;
    padding: 24px 24px 24px 362px;
    width: 742px;

    @include small {
      width: 472px;
      padding: 262px 24px 24px;
    }

    @include mobile {
      width: 100%;
      padding: 179px 24px 24px;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 339px;
      background-size: cover;
      background-image: url('../../../../../assets/img/img-score-send-introduction-lg.svg');
      background-color: #324dc7;
      background-repeat: no-repeat;

      @include small-format {
        background-image: url('../../../../../assets/img/img-score-send-introduction-sm.svg');
        width: inherit;
        height: 230px;
        background-position: left center;
      }

      @include mobile {
        height: 155px;
      }
    }

    .cb-modal-title {
      font-size: 21px !important;
      line-height: 1em !important;

      @include mobile {
        font-size: 16px !important;
        line-height: 1.5em !important;
      }
    }

    .cb-modal-header {
      position: initial;

      @include mobile {
        margin-bottom: 12px;
      }

      .cb-btn-close {
        top: 22px !important;
        right: 20px !important;

        @include mobile {
          top: 8px !important;
          right: 10px !important;
        }

        @include small-format {
          background: transparent;
          color: #ffffff;

          &:focus {
            box-shadow: inset 0 0 1px 2px #ffffff;
          }
          &:hover {
            background: #ffffff;
            color: #1e1e1e;
          }
        }
      }
    }

    .cb-modal-content {
      @include mobile {
        font-size: 0.875em;
      }
    }

    .cb-modal-footer {
      @include mobile {
        button {
          width: 100%;
        }
      }
    }
  }
}

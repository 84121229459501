@import '../../../../../assets/scss/queries-mixins';

.credit-policy {
  h3 {
    @include mobile {
      font-size: 24px;
    }
  }
  p {
    @include media('lg', 'xl', '2xl', '3xl') {
      font-size: 21px;
    }
  }
}

@import './queries-mixins.scss';

.apscores-app {
  height: auto;

  &:not(:empty) {
    min-height: 650px;
  }

  .cb-menu-list {
    background-color: white !important;
  }

  .cb-no-focus {
    &:focus {
      outline: none;
    }
  }

  .cb-disabled {
    pointer-events: none;
    color: #989795 !important;
    cursor: not-allowed !important;
  }

  .cb-btn-circle {
    &.cb-btn-xs {
      font-size: 0.6rem;
      height: 1.25rem !important;
      width: 1.25rem !important;
    }
    &.cb-btn1-res {
      font-size: 0.75rem;
      height: 1.5rem !important;
      width: 1.5rem !important;
      display: inline-block;
      position: relative;
      top: -3px;

      @include mobile {
        font-size: 0.5rem;
        height: 1.1rem !important;
        width: 1.1rem !important;
      }

      @include media('md', 'sm') {
        top: 0;
      }
    }
  }

  .cb-btn-icon-loading {
    min-height: 48px;
    min-width: 62px;

    > .cb-glyph {
      margin-left: 0 !important;
    }
  }

  .cb-no-wrap {
    white-space: nowrap;
  }

  .cb-md-wrap {
    @include medium {
      white-space: normal;
    }
  }

  .cb-gray3-border {
    border-color: #b2b2b2 !important;
  }

  .cb-full-width {
    width: 100%;
  }

  .cb-strike-through {
    text-decoration: line-through;
  }

  .cb-global-footer-reset {
    margin-bottom: -48px;
    padding-bottom: 96px;
    min-height: 1200px;
    border-bottom: 1px solid #ccc;
  }

  .cb-line-height-normal {
    line-height: 1.25em !important;
  }

  .cb-line-height-15 {
    line-height: 1.5em !important;
  }

  .cb-line-height-2 {
    line-height: 2em !important;
  }

  .cb-font-size-xs-small {
    @include mobile {
      font-size: 14px !important;
    }
  }

  .cb-checkbox-sm {
    label {
      line-height: normal;
      font-size: 0.875em;
    }
    .cb-span {
      width: 16px !important;
      min-width: 16px !important;
      height: 16px !important;

      &:after {
        top: 1px !important;
        left: 5px !important;
        width: 5px !important;
        height: 10px !important;
      }
    }
  }

  table {
    th.cb-column-checkbox-only,
    td.cb-column-checkbox-only {
      min-width: auto !important;
      text-align: center;
      label {
        margin-bottom: 0;
      }

      .cb-checkbox .cb-span {
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }

  .cb-dl {
    dt {
      margin-left: 0;
      font-family: 'Roboto-Bold', sans-serif !important;
      text-transform: uppercase;
      font-size: 12px;
      margin-top: 1.5em;
    }
    dd {
      margin-left: 0;
    }
  }

  .cb-notification-condensed {
    .cb-notification-container {
      padding: 12px;
      .cb-notification-header {
        .cb-glyph {
          margin-right: 8px;
          font-size: 0.75rem;
        }
        .cb-notification-title {
          font-size: 1rem;
        }

        & + .cb-notification-content {
          font-size: 0.875rem;
          margin-top: 4px;
          padding-left: 32px;
        }
      }
    }
  }

  .apscores-accordion-list {
    .cb-accordion-controls {
      align-items: center;
      .cb-accordion-title {
        margin-right: 24px;
      }
      .cb-accordion-buttons {
        @include mobile {
          margin-left: 0 !important;
          padding-left: 0 !important;
        }
      }
    }
  }

  .cb-dropdown-flush-left {
    > a {
      padding: 0;
      height: auto;
    }
  }

  .vertical-align-middle {
    vertical-align: middle;
  }
}

$border-color: #d8d8d8;
$link-color: #000000;

.apscores-nav {
  border: solid 1px $border-color;
  background-color: #ffffff;
  padding: 10px 0;

  a {
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid $border-color;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .apscores-nav-active {
    text-decoration: underline;
  }
}

$breakpoints-min: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1248px,
  xl: 1344px,
  '2xl': 1440px,
  '3xl': 1728px,
);

$breakpoints-max: (
  xs: 767px,
  sm: 1023px,
  md: 1247px,
  lg: 1343px,
  xl: 1439px,
  '2xl': 1727px,
  '3xl': 5000px,
);

@mixin mobile {
  @media (max-width: map_get($breakpoints-min, 'sm') - 1px) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: map_get($breakpoints-min, 'sm')) {
    @content;
  }
}

@mixin small {
  @media (min-width: map_get($breakpoints-min, 'sm')) and (max-width: map_get($breakpoints-max, 'sm')) {
    @content;
  }
}

@mixin medium {
  @media (min-width: map_get($breakpoints-min, 'md')) and (max-width: map_get($breakpoints-max, 'md')) {
    @content;
  }
}

@mixin large-format {
  @media (min-width: map_get($breakpoints-min, 'md')) {
    @content;
  }
}

@mixin small-format {
  @media (max-width: map_get($breakpoints-max, 'sm')) {
    @content;
  }
}

@mixin large {
  @media (min-width: map_get($breakpoints-min, 'lg')) and (max-width: map_get($breakpoints-max, 'lg')) {
    @content;
  }
}

@mixin x-large {
  @media (min-width: map_get($breakpoints-min, 'xl')) and (max-width: map_get($breakpoints-max, 'xl')) {
    @content;
  }
}

@mixin x-large2 {
  @media (min-width: map_get($breakpoints-min, '2xl')) and (max-width: map_get($breakpoints-max, '2xl')) {
    @content;
  }
}

@mixin x-large3 {
  @media (min-width: map_get($breakpoints-min, '3xl')) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpoints-min, $key)) and (max-width: map-get($breakpoints-max, $key)) {
      @content;
    }
  }
}

.apscores-faqs {
  .cb-accordion-panels {
    background: white;

    .apscores-faqs-content {
      p + ul {
        margin-top: 4px;
      }
    }
  }
}

@import '../../../../assets/scss/queries-mixins.scss';
@import '../content.scss';

.apscores-send-scores-module {
  ul {
    li {
      line-height: 32px;

      @include mobile {
        line-height: inherit;
      }

      > div:first-child {
        font-size: 21px;

        @include mobile {
          font-size: 16px;
        }
      }
    }
  }
}

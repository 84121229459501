.stepper-align-left {
  li {
    text-align: left !important;
    padding: 0 !important;

    &.cb-stepper-completed,
    &.cb-stepper-active {
      .cb-glyph {
        color: #324dc7 !important;
      }
    }

    &.cb-stepper-completed {
      &:after {
        background: #324dc7 !important;
      }
    }

    &:after {
      left: 20px !important;
    }

    &:last-child {
      width: auto !important;
    }
  }
}

.stepper-standard {
  .cb-stepper {
    li {
      &.cb-stepper-complete,
      &.cb-stepper-active {
        .cb-glyph, .cb-icon {
          &:before {
            color: #ffffff !important;
          }
          &:after {
            background-color: #0077c8 !important;
            border-color: #0077c8 !important;
          }
        }
      }
    }
  }
}
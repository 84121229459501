@import '../../../../assets/scss/queries-mixins.scss';

.apscores-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  h4 {
    font-size: 21px;
    font-weight: 500;
    padding: 36px 25px;
    color: #ffffff;
    z-index: 1;
    position: relative;
  }
}

.apscores-heading-divider {
  font-size: 21px;
  text-transform: uppercase;
  padding-right: 24px;
  margin-bottom: 1em;

  .archived-label {
    font-size: 0.625em;
    padding: 8px 16px;
  }

  .year-label {
    white-space: nowrap;
  }

  @include mobile {
    text-align: center;
  }

  span {
    width: 100%;
    height: 1px;
  }
}

.apscores-orders-card {
  .cb-accordion-panel-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .cb-accordion-controls {
    padding: 16px 24px 8px 24px;
    font-size: 0.875rem;

    .cb-accordion-title {
      h3 {
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .apscores-accordion-header {
    width: 100%;

    > div:first-child {
      width: 130px;
    }
  }
}

@import '../../../assets/scss/queries-mixins.scss';

.apscores-card {
  margin-bottom: 2em;
  overflow: hidden;
  min-height: 300px;

  .apscores-card-heading {
    height: 97px;

    @include x-large3 {
      height: 112px;
    }

    @include medium {
      height: 92px;
    }

    h4 {
      padding: 0 32px;
      width: 100%;

      @include mobile {
        text-align: center;
        padding: 0 24px;
      }
    }
  }

  .apscores-card-body {
    padding: 32px 0;
    margin: 0;
    min-height: 200px;

    @include mobile {
      display: block !important;
      margin: 0 auto;
    }

    p + p {
      margin-top: 1em;
    }

    @include media('3xl', 'xl') {
      padding: 48px 0;
    }

    @include media('lg', 'sm') {
      padding: 40px 0;
    }

    .apscores-card-col-left {
      box-sizing: border-box;
      margin: 0 24px; // 2xl, lg, md, xl

      > div {
        width: 224px;
        @include medium {
          width: 130px;
        }
      }

      @include small {
        margin: 0 32px;
      }

      @include x-large3 {
        margin: 0 48px;
      }
    }

    .apscores-card-col-right {
      padding: 0 32px; // 2xl, lg, sm, xl

      @include x-large3 {
        padding: 0 48px;
      }

      @include medium {
        padding: 0 20px;
      }

      @include mobile {
        padding: 0 24px;

        > div {
          width: 224px;
        }
      }
    }

    .apscores-badge {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    .apscores-intro {
      font-weight: 300;
      font-size: 21px;

      strong {
        font-weight: 500;
      }

      @include mobile {
        font-size: 14px;
        font-weight: 400;
      }

      @include media('md', 'lg') {
        font-size: 18px;
      }
    }
  }
}

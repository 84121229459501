// this file should be temporary
.apscores-app {
  label {
    font-weight: inherit !important;
  }
  legend {
    border-bottom: 0 !important;
    font-family: inherit !important;
  }

  // Select fix
  .cb-select-container {
    span.cb-select .cb-select-arrow {
      border-left: none;
    }
  }

  // Local Navigation Resets
  .cb-site-name {
    float: none !important;
    height: auto !important;
    padding-top: 0 !important;
  }
  .cb-local-navigation {
    height: auto !important;
    border: none !important;
  }
  .cb-menu-list {
    margin-bottom: 0 !important;

    .cb-dropdown-menu ul {
      display: flex !important;

      li {
        padding: 0 !important;
        border-bottom: none !important;

        a {
          font-size: inherit !important;
        }
      }
    }

    > ul > li {
      padding: 0 !important;
      margin: 0 !important;
      float: none !important;
      border: none !important;

      &:hover,
      &:focus {
        background: transparent !important;
        cursor: default !important;
      }

      a {
        line-height: inherit !important;
        border: none !important;

        &:after {
          content: '' !important;
        }
      }
    }
  }

  .cb-anchor-menu {
    border: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
  }

  .cb-accordion-controls {
    text-align: inherit !important;
    padding-bottom: 0 !important;

    .cb-accordion-title {
      margin-bottom: 0 !important;
    }
  }
}

.cb-icon-icn_arrow-up::before {
  content: "\e995" !important;
}

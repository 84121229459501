@import '../../../../../assets/scss/queries-mixins.scss';

.apscores-sendscores-autosuggest {
  > p:first-child {
    font-size: 21px;

    @include media('sm', 'md') {
      font-size: 16px;
    }

    @include mobile {
      font-size: 16px;
      font-weight: 400;
      color: #505050;
    }
  }

  > p:last-child {
    @include mobile {
      margin-top: 12px;
      font-size: 12px;
    }
  }
}

.apscores-toggle-buttons {
  margin-bottom: 16px;
  label {
    text-align: center;
    position: relative;
    border: 1px solid #ccc;
    text-transform: uppercase;
    background: #fff;
    display: block;
    height: 48px;
    min-width: 48px;
    font-size: 20px;
    margin-bottom: 0;

    > .apscores-toggle-button-label {
      font-size: 0.75rem;
      font-weight: 500 !important;
    }

    > i {
      vertical-align: middle;
    }

    & + label {
      margin-left: -1px;
      padding-left: 1px;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &:hover,
    &:focus {
      background: #f8f8f8;
      cursor: pointer;
    }

    &.apscores-toggle-button-selected {
      background: #d9d9d9;
      cursor: default;
    }

    input {
      position: absolute;
      left: -9999px;
    }
  }
}

@import '../../../assets/scss/queries-mixins.scss';

.apscores-content-callout {
  h4 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;

    @include mobile {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

@import '../../../../../assets/scss/queries-mixins.scss';
@import '../../../../../assets/scss/variables.scss';

.apscores-banner.apscores-banner-award {
  background-position: top right;
  background-size: cover;

  &:before {
    background-image: linear-gradient(
      to left,
      rgba($overlay-blue, 0.45) 0%,
      rgba($overlay-blue, 0.65) 25%,
      rgba($overlay-blue, 0.95) 70%,
      $overlay-blue 100%,
      rgba($overlay-blue, 0.95) 100%
    );
  }

  @include mobile {
    background-position: top 0 right -100px;

    &:before {
      background-image: linear-gradient(
        to left,
        rgba($overlay-blue, 0.35) 0%,
        rgba($overlay-blue, 0.9) 75%,
        $overlay-blue 80%,
        rgba($overlay-blue, 0.95) 100%
      );
    }
  }

  @each $awCode, $map in $award-map {
    &.apscores-banner-award-#{$awCode} {
      $url: '../../../../../assets/img/' + map-get($map, img);
      background-image: url($url);
    }
  }
}

.apscores-badge-award {
  min-height: 108px;
  height: auto;
  width: inherit;

  @each $awCode, $map in $award-map {
    &.apscores-badge-award-#{$awCode} {
      background-image: url('../../../../../assets/img/'+ map-get($map, badge));
    }
  }
}

@import '../../../assets/scss/queries-mixins.scss';

.apscores-actionbar-open {
  padding-bottom: 100px;
}

.apscores-actionbar {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 100;
  width: 100%;
  padding: 12px 0;

  a,
  button {
    width: 252px;

    @include mobile {
      width: 100%;
    }
  }
}

@import '../../../../assets/scss/queries-mixins.scss';
@import '../../../../assets/scss/variables.scss';

$img-score-release-date: '../../../../assets/img/img-score-release.webp';

.apscores-score-release {
  position: relative;
  color: #ffffff;
  background-image: url($img-score-release-date);
  background-repeat: no-repeat;
  background-size: cover;

  @include mobile {
    background-position: top 0 right 28%;
  }

  @include medium {
    background-position: top 0 right 30%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      $overlay-blue,
      rgba($overlay-blue, 0.9) 52%,
      rgba($overlay-blue, 0.5)
    );
  }

  > * {
    z-index: 1;
    position: relative;
  }
}

@import '../../../../../assets/scss/queries-mixins.scss';
@import '../../../../../assets/scss/variables.scss';

.apscores-banner {
  background-position: center center;

  @each $exCode, $props in $course-map {
    &.apscores-banner-#{$exCode} {
      // checks if the exCode is present in map (legacy course, if not)
      @if ($exCode) {
        $url: '../../../../../assets/img/' + map-get($props, img);
        background-image: url($url);
        background-size: map-get($props, size);
        background-position: map-get($props, position);


        &.apscores-banner-score {
          &:before {
            background: map-get($props, background)
          }
        }
      }
    }
  }

  // For legacy courses
  &.apscores-banner-score {
    &:before {
      background: $overlay-blue
    }
  }

  &.apscores-banner-delay {
    &:before {
      background: rgba($overlay-gray, 0.9);
    }
  }
  &.apscores-banner-cancel {
    background: $bg-gray1;
    h4 {
      color: $text-gray1;
      font-weight: 500;
    }
  }
}

.apscores-badge-score {
  min-height: 135px;
  width: 156px;
  margin: 0 auto;
  font-size: 72px;
  font-weight: 700;
  font-family: Roboto-Bold, sans-serif;
  text-align: center;
  padding-top: 40px;

  @include medium {
    min-height: 113px;
    width: 130px;
    font-size: 60px;
    padding-top: 33px;
  }

  @each $score, $badge in $score-map {
    &.apscores-badge-score-#{$score} {
      $base: '../../../../../assets/img/';
      $img: $badge;
      background-image: url($base + $img);

      @include medium {
        $md: str-insert($img, '-md', str-index($img, '.'));
        background-image: url($base + $md);
      }
    }
  }
}

.apscores-delay-badge {
  background: url('../../../../../assets/img/' + $delay-score-badge);
  margin: 0 auto;
  min-height: 109px;
  width: 109px;
}

.apscores-cancel-badge {
  background: url('../../../../../assets/img/' + $cancel-score-badge);
  margin: 0 auto;
  min-height: 112px;
  width: 112px;
}

@import '../../../assets/scss/queries-mixins.scss';

$width: 475px;

@keyframes slide-in {
  0% {
    right: (-$width);
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    right: 0;
    opacity: 1;
  }

  100% {
    right: (-$width);
    opacity: 0;
  }
}

.apscores-panel-open {
  overflow: hidden;
}

.apscores-panel {
  display: inline;

  .apscores-panel-overlay {
    background-color: rgba(30, 30, 30, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .apscores-panel-content {
    position: fixed;
    top: 0;
    width: $width;
    height: 100vh;
    z-index: 1001;
    background-color: white;
    animation: slide-in 0.4s forwards;

    .apscores-panel-heading {
      position: relative;

      h3 {
        line-height: 55px !important;
      }

      > button {
        position: absolute;
        left: 15px;
        top: 10px;
      }
    }

    .apscores-panel-body {
      overflow-y: scroll;
      height: calc(100% - 55px);
      padding-bottom: 100px;
    }

    @include mobile {
      width: 100%;
    }
  }

  &.transitioning {
    .apscores-panel-content {
      animation: slide-out 0.4s forwards;
    }
    .apscores-panel-overlay {
      background: transparent;
    }
  }
}

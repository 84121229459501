@import '../../../../../assets/scss/queries-mixins.scss';

.apscores-delivery-selection {
  label {
    width: 100%;
    justify-content: left;

    .cb-span {
      @include media('lg', 'xl', '2xl', '3xl') {
        margin-top: 3px;
      }
    }

    > div {
      width: 100%;
    }
  }
}
